import { updateUserAttributes as amplifyUpdateUserAttributes } from 'aws-amplify/auth';
import api from './api';

export const getUserAttributes = (sub = null) => api('GetUserAttributes', {
  sub,
});

export const updateUserAttributes = (userAttributes, sub = null) => api('UpdateUserAttributes', {
  userAttributes,
  sub,
});

// Should only be used for build-in cognito attributes, use updateUserAttributes for custom attributes
export const updateCognitoAttributes = (userAttributes) => amplifyUpdateUserAttributes({
  userAttributes,
});

export const userCRUD = async (action, user) => {
  const response = await api('UserCRUD', {
    user,
    action,
  });
  if (response?.errorMessage) {
    console.error(response);
    throw new Error(response.errorMessage);
  }
  return response;
};

export const getEndSessionUrl = (logoutUrl) => (
  api('TenantUser', { action: 'getEndSessionUrl', logoutUrl })
);

export const startChangeTenantUserPassword = () => (
  api('TenantUser', { action: 'startChangePassword' })
);

export const completeChangeTenantUserPassword = (confirmationCode, newPassword) => (
  api('TenantUser', { action: 'completeChangePassword', confirmationCode, newPassword })
);
