import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

export default function TenantUserView(props) {
  const {
    email,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen>
      <ModalHeader>
        Confirm Password Change
      </ModalHeader>
      <ModalBody>
        <div>
          In order to change your password a confirmation code will be sent to your email address:&nbsp;
          <span className="userAttribute">
            { email }
          </span>
          . Please make sure you have access to your email account before clicking Proceed.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          id="ok"
          color="primary"
          onClick={() => {
            setIsLoading(true);
            props.onAccept();
          }}
        >
          { isLoading ? <Spinner size="sm" color="white" /> : 'Proceed' }
        </Button>
        &nbsp;
        <Button
          id="cancel"
          color="secondary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
