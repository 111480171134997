import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Spinner,
} from 'reactstrap';

const renderValidationError = (validation) => ((validation && validation.isInvalid) ? (<small className="validationError text-danger">{validation.message}</small>) : null);

const renderSuccessMessage = (onClose) => (
  <>
    <ModalBody>
      <div id="successMessage">
        Password was successfully changed.
      </div>
    </ModalBody>
    <ModalFooter>
      <Button
        color="primary"
        onClick={() => onClose()}
      >
        Close
      </Button>
    </ModalFooter>
  </>
);

export default function ChangePasswordFormView(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [validations, setValidations] = useState({});
  const [saveWasSuccessful, setSaveWasSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen>
      <ModalHeader>Change Password</ModalHeader>
      { saveWasSuccessful
        ? renderSuccessMessage(props.onClose)
        : (
          <>
            <ModalBody>
              {
                props.isTenantUser && (
                  <FormGroup>
                    <Label for="confirmationCode">Confirmation Code</Label>
                    <Input
                      id="confirmationCode"
                      name="confirmationCode"
                      type="input"
                      className={validations.confirmationCode ? 'is-invalid' : null}
                      value={confirmationCode}
                      onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                    {renderValidationError(validations.confirmationCode)}
                  </FormGroup>
                )
              }
              {
                !props.isTenantUser && (
                  <FormGroup className="changePassword">
                    <Label for="oldPassword">Old Password</Label>
                    <Input
                      id="oldPassword"
                      name="oldPassword"
                      type="password"
                      className={validations.oldPassword ? 'is-invalid' : null}
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    {renderValidationError(validations.oldPassword)}
                  </FormGroup>
                )
              }
              <FormGroup>
                <Label for="newPassword">New Password</Label>
                <Input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  className={validations.newPassword ? 'is-invalid' : null}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {renderValidationError(validations.newPassword)}
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className={validations.confirmPassword ? 'is-invalid' : null}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {renderValidationError(validations.confirmPassword)}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                id="save"
                color="primary"
                onClick={async () => {
                  setIsLoading(true);
                  await props.onSave(
                    setSaveWasSuccessful,
                    setValidations,
                    oldPassword,
                    newPassword,
                    confirmPassword,
                    confirmationCode,
                  );
                  setIsLoading(false);
                }}
              >
                { isLoading ? <Spinner size="sm" color="white" /> : 'Save' }
              </Button>
              &nbsp;
              <Button
                id="cancel"
                color="secondary"
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
    </Modal>
  );
}
