import React, { useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faSpinner } from '@fortawesome-free-solid-svg-icons-v6';
import styles from './DownloadDropdown.module.scss';
import api from '../../../services/api';
import { useModal } from '../../../hooks/useModal';
import { useEnabledFeatures } from '../../../hooks/useEnabledFeatures';

const bytesToBase64 = (bytes) => btoa(Array.from(bytes, (byte) => String.fromCodePoint(byte)).join(''));

const gunzip = async (data) => {
  const compressedData = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
  const blob = new Blob([compressedData]);
  const ds = new DecompressionStream('gzip');
  const decompressedStream = new Response(blob.stream().pipeThrough(ds));
  const decompressedArrayBuffer = await decompressedStream.arrayBuffer();
  return bytesToBase64(new Uint8Array(decompressedArrayBuffer));
};

const downloadBase64 = (base64, filename) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', linkSource);
  downloadLink.setAttribute('download', filename);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

function DownloadDropdown({ requestId, isUat, bankingAvailable }) {
  const { downloadPdfReports } = useEnabledFeatures({ env: isUat ? 'uat' : 'prod' });
  const { showModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => setIsOpen((prevState) => !prevState);

  const downloadReport = async (reportType) => {
    setIsLoading(true);
    const supportsGzip = typeof DecompressionStream === 'function';
    const response = await api('DownloadReportPdf', {
      requestId, isUat, gzip: supportsGzip, reportType,
    });
    if (!response.success) {
      console.error('Error generating PDF');
      showModal({
        body: 'Error generating a PDF report, please contact support if the issue persists.',
        title: 'Error generating PDF',
      });
      setIsLoading(false);
      return;
    }
    let { data } = response;
    if (supportsGzip) {
      data = await gunzip(data);
    }
    downloadBase64(data, `${reportType}-report-${requestId}.pdf`);
    setIsLoading(false);
  };

  if (!downloadPdfReports) {
    return null;
  }

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} direction="down">
      <DropdownToggle className={styles.dropdownButton} caret color="primary" disabled={isLoading}>
        {'Download '}
        {isLoading
          ? (
            <FontAwesomeIcon
              className={styles.loadingIcon}
              icon={faSpinner}
              title="Generating PDF"
            />
          )
          : (
            <FontAwesomeIcon
              icon={faFilePdf}
              title="Download PDF"
            />
          )}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => downloadReport('scoring')}>
          {' Scoring Report'}
        </DropdownItem>
        <DropdownItem onClick={() => downloadReport('banking')} disabled={!bankingAvailable}>
          {' Banking Report'}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default DownloadDropdown;
