import React, { Component, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes } from 'aws-amplify/auth';
import LogRocket from 'logrocket';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  Card,
  CardTitle,
  CardText,
} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
  faPlus,
  faSearch,
  faLandmark,
  faGraduationCap,
  faCity,
  faInfoCircle,
  faArrowRight,
  faExclamationTriangle,
  faSyncAlt,
  faAngleDoubleDown,
  faUpload,
  faEdit,
  faUsersCog,
  faCopy,
  faLock,
  faQuestionCircle,
  faTimes,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import withFrameBusting from './hoc/WithFrameBusting';
import { buildEnabledRoutes } from './features';
import TopHeaderNotification from './components/TopHeaderNotification';
import Header from './containers/Header';
import Loader from './components/Loader';
import Home from './containers/Home';
import AlertsWrapper from './containers/AlertsWrapper';
import withAuthentication from './hoc/WithAuthentication';

import awsConfig from './aws-exports';
import functionsConfig from './config/functions-config';
import SITE_PATHS from './config/sitePaths';
import {
  LOGROCKET_APPID,
  COGNITO_DOMAIN,
} from './config/console-configuration';

import './styles/App.scss';
import Logout from './components/Logout';
import ChangePassword from './components/ChangePassword';
import UserProfile from './components/UserProfile';
import Impersonate from './components/Impersonate';
import ImpersonationBanner from './components/ImpersonationBanner';
import SupportModal from './components/SupportModal';
import getClientConfig from './utils/get-client-config';
import { getClientId } from './utils/impersonation';
import { trustScienceClientId, clientIdAttribute } from './constants';
import { EnabledFeaturesProvider, useEnabledFeatures } from './hooks/useEnabledFeatures';
import { useModal, ModalProvider } from './hooks/useModal';
import VerificationBanner from './components/VerificationBanner';
import CheckVerification from './components/CheckVerificationModal';
import * as userService from './services/user-services';

library.add(
  faTrash,
  faPlus,
  faSearch,
  faLandmark,
  faGraduationCap,
  faCity,
  faInfoCircle,
  faArrowRight,
  faPlus,
  faExclamationTriangle,
  faSyncAlt,
  faAngleDoubleDown,
  faUpload,
  faEdit,
  faUsersCog,
  faCopy,
  faLock,
  faQuestionCircle,
  faTimes,
  faDownload,
);

if (LOGROCKET_APPID) {
  LogRocket.init(LOGROCKET_APPID, {
    network: {
      // APP-2018 (NB) For now let's not track http requests/responses.
      //               We can add it later with appropriate PII scrubbing if we believe it is useful.
      requestSanitizer: () => null,
    },
    // APP-2018 (NB) We will also by default sanitize all inputs. We can loosen this later if required.
    dom: {
      inputSanitizer: true,
    },
    console: {
      isEnabled: {
        log: true,
        debug: true,
      },
    },
  });
}

const identifyUserToLogRocket = (user, clientId) => {
  if (!LOGROCKET_APPID) return;
  LogRocket.identify(user.sub, {
    name: `${user.given_name} ${user.family_name}`,
    email: user.username,
    clientId,
  });
};

awsConfig.oauth = {
  domain: COGNITO_DOMAIN,
  scope: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
  redirectSignIn: [window.location.origin, 'auth'].join('/'),
  redirectSignOut: window.location.origin,
  responseType: 'code',
};

awsConfig.aws_cloud_logic_custom = [
  ...(awsConfig.aws_cloud_logic_custom || []),
  ...functionsConfig.API.endpoints,
];

Amplify.configure(awsConfig);

const renderFatalError = () => {
  setTimeout(() => {
    window.location.href = SITE_PATHS.SIGNOUT;
  }, (5000));
  return (
    <div className="container fatal-error">
      <Card body inverse color="danger">
        <CardTitle tag="h5">Fatal error occurred</CardTitle>
        <CardText>Contact support, You will be logged out in 5 seconds...</CardText>
      </Card>
    </div>
  );
};

const renderLoader = (isLoading) => {
  if (isLoading) return <Loader />;
  return null;
};

const retrieveSystemAlertMessages = () => [];

const renderAlertsMessages = (loading) => {
  if (loading) return null;

  const systemMessages = retrieveSystemAlertMessages();

  if (systemMessages.length === 0) {
    return null;
  }

  return (
    <AlertsWrapper messages={systemMessages} />
  );
};

function RouterSwitch({
  loading, clientId, clientInfo, state,
}) {
  const features = useEnabledFeatures({ env: 'any' });

  if (loading) return null;
  return (
    <Switch>
      <Route
        exact
        path={SITE_PATHS.ROOT}
        render={() => (
          <Home
            clientId={clientId}
            clientInfo={clientInfo}
          />
        )}
      />
      <Route
        path={SITE_PATHS.LOGOUT}
        render={Logout}
      />
      {buildEnabledRoutes(features, state)}
      <Redirect to={SITE_PATHS.ROOT} />
    </Switch>
  );
}

function NoConsoleAccessModal() {
  const { showModal } = useModal();
  useEffect(() => {
    showModal({
      header: 'Account Disabled',
      body: 'Access to this account has been disabled. Please contact support.',
    });
  // Run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userAccount: {},
      userAttributes: {},
      clientConfig: {
        features: {},
      },
      uatClientConfig: null,
      session: {},
      showChangePassword: false,
      showUserProfile: false,
      showSupportModal: false,
      showEmailVerification: false,
      error: false,
    };
  }

  async componentDidMount() {
    try {
      const [clientConfigResult, userAttributes] = await Promise.all([
        getClientConfig({ forceRefresh: true, includeUat: true }),
        userService.getUserAttributes(),
      ]);
      const { user, clientId, result: { clientConfig, uatClientConfig, session } } = clientConfigResult;
      if (session.ttl) {
        setTimeout(() => {
          this.setState((state) => ({ showImpersonateModal: true, session: { ...state.session, expired: true } }));
        }, (session.ttl));
      }

      identifyUserToLogRocket(user, clientId);

      this.setState({
        userAccount: user,
        userAttributes,
        clientId,
        clientConfig,
        uatClientConfig,
        loading: false,
        session,
      });
    } catch (error) {
      console.error('ERROR: Unable to get client config', error);
      this.setState({ loading: false, error: true });
    }
  }

  async updateProfile() {
    try {
      const userAccount = await fetchUserAttributes();
      this.setState((prevState) => ({
        userAccount: {
          ...prevState.userAccount,
          ...userAccount,
        },
      }));
    } catch (error) {
      console.error('ERROR: Unable to update user profile', error);
      this.setState({ loading: false, error: true });
    }
  }

  renderChangePassword(loading) {
    if (loading) return null;
    const component = (
      <ChangePassword
        userAccount={this.state.userAccount}
        onClose={() => this.setState({ showChangePassword: false })}
      />
    );
    return this.state.showChangePassword ? component : null;
  }

  renderUserProfile(loading) {
    if (loading) return null;
    const component = (
      <UserProfile
        user={this.state.userAccount}
        enabledFeatures={this.state.clientConfig.features}
        onClose={() => {
          this.updateProfile().then(() => {
            this.setState({ showUserProfile: false });
          });
        }}
      />
    );
    return this.state.showUserProfile ? component : null;
  }

  renderImpersonate(loading) {
    if (loading) return null;
    if (!this.state.userAccount) return null;
    const component = (
      <Impersonate
        onClose={() => this.setState({ showImpersonateModal: false })}
        session={this.state.session}
        switchableAccounts={this.state.clientConfig?.switchableAccounts}
        isTrustScienceClient={this.state.userAccount[clientIdAttribute] === trustScienceClientId}
        clientName={this.state.clientConfig.displayName}
      />
    );
    return this.state.showImpersonateModal ? component : null;
  }

  renderSupportModal(loading) {
    if (loading) return null;
    const component = (
      <SupportModal
        onClose={() => this.setState({ showSupportModal: false })}
      />
    );
    return this.state.showSupportModal ? component : null;
  }

  renderEmailVerification(loading, searchParams) {
    if (loading) return null;
    const component = (
      <CheckVerification
        userAccount={this.state.userAccount}
        searchParams={searchParams}
        onClose={() => {
          this.setState({ showEmailVerification: false });
          window.location.href = '/';
        }}
      />
    );
    return component;
  }

  render() {
    const { userAccount, loading, error } = this.state;
    if (error) {
      return renderFatalError();
    }
    const enabledFeatures = this.state.clientConfig.features;
    const { disableConsole } = enabledFeatures;

    return (
      <Router>
        <ModalProvider>
          <EnabledFeaturesProvider
            enabledFeatures={enabledFeatures}
            enabledFeaturesUat={this.state.uatClientConfig?.features}
            userAttributes={this.state.userAttributes}
            overrides={{
              dataSourceApi: this.state.clientConfig?.dataSourceApi?.enabled,
            }}
            overridesUat={{
              dataSourceApi: this.state.uatClientConfig?.dataSourceApi?.enabled,
            }}
          >
            {Object.keys(userAccount).length > 0 && !userAccount.email_verified
            && (
              <VerificationBanner
                session={this.state.session}
                userAccount={userAccount}
              />
            )}
            {this.state.session.impersonating
            && <ImpersonationBanner clientName={this.state.clientConfig.displayName} />}
            <TopHeaderNotification />
            <div className="center-wrapper">
              <Header
                userAccount={userAccount}
                clientConfig={this.state.clientConfig}
                uatClientConfig={this.state.uatClientConfig}
                onUserProfile={() => this.setState({ showUserProfile: true })}
                onChangePassword={() => this.setState({ showChangePassword: true })}
                onImpersonate={() => this.setState({ showImpersonateModal: true })}
                onSupport={() => this.setState({ showSupportModal: true })}
                onVerification={() => this.setState({ showEmailVerification: true })}
                session={this.state.session}
              />

              {this.renderChangePassword(loading)}
              {this.renderUserProfile(loading)}
              {this.renderImpersonate(loading)}
              {this.renderSupportModal(loading)}

              {disableConsole ? <NoConsoleAccessModal /> : (
                <main className="content">
                  {renderLoader(loading)}
                  {renderAlertsMessages(loading)}
                  <RouterSwitch
                    loading={loading}
                    clientId={getClientId(this.state)}
                    clientInfo={this.state.clientConfig}
                    state={this.state}
                  />
                  {(new RegExp(`${SITE_PATHS.EMAIL_VERIFICATION}$`).test(window.location.pathname))
                && this.renderEmailVerification(loading, window.location.search)}
                </main>
              )}
            </div>
          </EnabledFeaturesProvider>
        </ModalProvider>
      </Router>
    );
  }
}

export default withFrameBusting(withAuthentication(App));
