import React from 'react';

const renderMonthlyAmount = (monthlyAmount) => (
  <span>
    $
    {Math.round(monthlyAmount)}
    <wbr />
    /month
  </span>
);

export default renderMonthlyAmount;
