export const colors = [
  '#265c74',
  '#716a9e',
  '#c66aa1',
  '#ff7a79',
  '#ffb326',
];

export const createScaleFont = (minSizeWithDefaultFont) => (size, componentSize) => {
  if (componentSize?.width < minSizeWithDefaultFont) {
    return (componentSize.width / minSizeWithDefaultFont) * size;
  }
  return size;
};
