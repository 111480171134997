import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faCog,
  faSignOutAlt,
  faUser,
  faKey,
  faExchangeAlt,
  faQuestionCircle,
  faExternalLinkAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import createDisplayTreeFromFeatures from './createDisplayTreeFromFeatures';
import TrustScienceLogo from '../../components/TrustScienceLogo';
import NavButton from './NavButton';
import NavDropdown from './NavDropdown';
import DropdownSubMenu from './DropdownSubMenu';
import findNavItemByMatchingURL from './findNavItemByMatchingURL';
import SITE_PATHS, { NODE_NAMES } from '../../config/sitePaths';
import './header.scss';
import { withUseEnabledFeatures } from '../../hooks/useEnabledFeatures';
import { getEndSessionUrl } from '../../services/user-services';
import cognitoUser from '../../utils/cognito-user';

library.add(faSignOutAlt, faUser, faKey, faExchangeAlt, faExternalLinkAlt, faQuestionCircle, faBook, faCog, faUserCircle);

const userFullName = (firstName = '', lastName = '') => `${firstName} ${lastName}`;

const buildSignOutUrl = async (userAttributes) => {
  const logoutUrl = [window.location.origin, SITE_PATHS.LOGOUT].join('');
  if (cognitoUser(userAttributes).isTenantUser) {
    const result = await getEndSessionUrl(logoutUrl);
    if (result.statusCode !== 200) {
      console.error('Invalid response while getting end session URL for tenant user pool', result);
      return logoutUrl;
    }
    try {
      return JSON.parse(result.body).endSessionUrl;
    } catch (error) {
      console.error('Error while parsing end session URL body', error);
      return logoutUrl;
    }
  }
  return logoutUrl;
};

const handleSignOut = (userAccount) => async () => {
  window.location.href = await buildSignOutUrl(userAccount);
};

const USER_MENU = 'userMenu';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMenuOpened: false,
      selectedNavPath: SITE_PATHS.ROOT,
      navigationItemTree: {},
      dropdownDepthLevel: 0,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.selectedNavPath !== nextProps.location.pathname) {
      this.setState({ selectedNavPath: this.props.location.pathname });
      return false;
    }

    return true;
  }

  gotoSection = (location) => {
    this.props.history.push(location);
    this.setState({ selectedNavPath: location });
  };

  toggleDropdowns(targetMenu) {
    const targetDropdownOpened = this.state[`${targetMenu}Opened`];
    this.setState({ [`${targetMenu}Opened`]: !targetDropdownOpened });
  }

  renderUserMenu() {
    if (!this.props.clientConfig) {
      return null;
    }

    const forceItemHighlight = false;

    const tree = createDisplayTreeFromFeatures(this.props.useEnabledFeaturesAnyProp, NODE_NAMES.USERMENU);

    if (Object.keys(tree).length > 0) {
      const subMenuItems = Object.keys(tree).map((itemName) => {
        const navItem = tree[itemName];

        if (navItem.type === 'multiple') {
          const selectedSubMenuItem = findNavItemByMatchingURL(navItem, this.state.selectedNavPath);
          const selected = typeof selectedSubMenuItem !== 'undefined';

          return (
            <DropdownSubMenu
              key={itemName}
              label={itemName}
              icon={navItem.icon || ''}
              navItem={navItem}
              clickHandler={this.gotoSection}
              selected={forceItemHighlight || selected}
            />
          );
        }
        return null;
      });

      return (
        <div className="configurable-usermenu-items">
          <DropdownItem divider />
          {subMenuItems}
        </div>
      );
    }
    return null;
  }

  renderNavMenu() {
    if (!this.props.clientConfig) {
      return null;
    }

    const tree = createDisplayTreeFromFeatures(this.props.useEnabledFeaturesProdProp, NODE_NAMES.NAVMENU);
    const treeUAT = this.props.uatClientConfig && createDisplayTreeFromFeatures(this.props.useEnabledFeaturesUatProp, NODE_NAMES.NAVMENU);

    return Object.keys({ ...tree, ...treeUAT }).map((itemName) => {
      let navItem = tree[itemName] ?? treeUAT[itemName];
      if (navItem.type === 'single' && treeUAT?.[itemName]?.type === 'multiple') {
        navItem = treeUAT[itemName];
      }
      let forceItemHighlight = false;
      if (navItem.pathsToForceHighlight) {
        forceItemHighlight = navItem.pathsToForceHighlight.find(
          (staticPath) => this.state.selectedNavPath.match(staticPath),
        );
      }
      if (navItem.type === 'single') {
        const selected = this.state.selectedNavPath === navItem.path;

        return (
          <NavButton
            label={itemName}
            clickHandler={this.gotoSection}
            navItem={navItem}
            selected={forceItemHighlight || selected}
            key={navItem.label}
          />
        );
      }

      if (navItem.type === 'multiple') {
        const selectedSubMenuItem = findNavItemByMatchingURL(navItem, this.state.selectedNavPath);
        const selected = typeof selectedSubMenuItem !== 'undefined';

        let dropdownNavItem = navItem;
        if (itemName === 'Scoring' && treeUAT?.[itemName]) {
          const uatItems = treeUAT[itemName].items.map((item) => ({
            ...item,
            label: `${item.label} (UAT)`,
            path: `${item.path}?env=uat`,
          }));
          if (uatItems.length > 0) {
            if (tree[itemName]) {
              dropdownNavItem = {
                ...tree[itemName],
                items: [...tree[itemName].items, { type: 'divider' }, ...uatItems],
              };
            } else {
              dropdownNavItem = {
                ...treeUAT[itemName],
                items: uatItems,
              };
            }
          }
        }

        return (
          <NavDropdown
            iconNav={!!navItem.icon}
            key={itemName}
            label={navItem.icon ? <FontAwesomeIcon icon={navItem.icon} /> : navItem.label}
            navItem={dropdownNavItem}
            clickHandler={this.gotoSection}
            selected={forceItemHighlight || selected}
          />
        );
      }

      return null;
    });
  }

  render() {
    if (!this.props.userAccount || !Object.keys(this.props.userAccount).length) return null;
    return (
      <header className="header-bar">
        <TrustScienceLogo />
        <nav className="header-nav">
          {this.renderNavMenu()}
        </nav>
        <Dropdown
          className="user-menu-dropdown"
          isOpen={this.state.userMenuOpened}
          toggle={() => this.toggleDropdowns(USER_MENU)}
        >
          <DropdownToggle data-cy="user-menu" color="white" outline={false} className="profile-icon">
            <FontAwesomeIcon icon="user-circle" size="lg" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem disabled>
              {userFullName(this.props.userAccount.given_name, this.props.userAccount.family_name)}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => this.props.onUserProfile()}>
              <FontAwesomeIcon icon="user" color="grey" size="sm" />
              User Profile
            </DropdownItem>
            <DropdownItem onClick={() => this.props.onChangePassword()}>
              <FontAwesomeIcon icon="key" color="grey" size="sm" />
              Change Password
            </DropdownItem>
            {this.props.clientConfig.features.allowSwitchingAccounts || this.props.session.impersonating
              ? (
                <DropdownItem onClick={() => this.props.onImpersonate()}>
                  <FontAwesomeIcon icon="exchange-alt" color="grey" size="sm" />
                  Switch Account
                </DropdownItem>
              )
              : null}
            <DropdownItem onClick={() => this.props.onSupport()}>
              <FontAwesomeIcon icon="question-circle" color="grey" size="sm" />
              Support
            </DropdownItem>
            {/* Configurable section of user menu here. For now, let's leave the rest of the usermenu here, but could
              refactor it to be more similar to the navmenu */}
            {this.renderUserMenu()}
            <DropdownItem divider />
            <DropdownItem onClick={handleSignOut(this.props.userAccount)}>
              <FontAwesomeIcon icon="sign-out-alt" color="grey" size="sm" />
              Sign Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

      </header>
    );
  }
}

export default withUseEnabledFeatures(
  withRouter(Header),
);
