import React, { useState } from 'react';
import { updatePassword } from 'aws-amplify/auth';
import FormValidator from '../../utils/FormValidator';
import cognitoUser from '../../utils/cognito-user';
import {
  startChangeTenantUserPassword,
  completeChangeTenantUserPassword,
} from '../../services/user-services';
import validationConfig from './validationConfig';
import ChangePasswordFormView from './ChangePasswordForm.view';
import TenantUserView from './TenantUser.view';
import ChangePasswordErrorView from './ChangePasswordError.view';

import './style.scss';

const getValidator = (newPassword) => (
  new FormValidator([
    ...validationConfig,
    {
      field: 'confirmPassword',
      method: 'equals',
      validWhen: true,
      message: 'The passwords do not match',
      options: [newPassword],
    },
  ])
);

const updateLocalPassword = async (
  setSaveWasSuccessful,
  setValidations,
  oldPassword,
  newPassword,
  confirmPassword,
) => {
  const validator = getValidator(newPassword);
  const results = validator.validate({ oldPassword, newPassword, confirmPassword });

  if (results.isInvalid) {
    setValidations(results);
  } else {
    try {
      await updatePassword({ oldPassword, newPassword });
      setSaveWasSuccessful(true);
    } catch (error) {
      console.error('Unable to change password:', error);
      setValidations({ oldPassword: { isInvalid: true, message: error.message } });
    }
  }
};

export default function ChangePassword(props) {
  const [isTenantUserAccepted, setIsTenantUserAccepted] = useState(false);
  const [errorResponse, setErrorResponse] = useState();
  const {
    isTenantUser,
  } = cognitoUser(props.userAccount);

  const updateTenantPassword = async (
    setSaveWasSuccessful,
    setValidations,
    oldPassword,
    newPassword,
    confirmPassword,
    confirmationCode,
  ) => {
    const validator = getValidator(newPassword);
    const results = validator.validate({
      newPassword,
      confirmPassword,
      confirmationCode,
    });

    if (results.isInvalid) {
      setValidations(results);
    } else {
      try {
        const response = await completeChangeTenantUserPassword(confirmationCode, newPassword);
        if (response.statusCode === 200) {
          setSaveWasSuccessful(true);
        } else {
          setErrorResponse(JSON.parse(response.body));
        }
      } catch (error) {
        console.error('Unable to change tenant user password:', error);
        setValidations({ oldPassword: { isInvalid: true, message: error.message } });
      }
    }
  };

  if (errorResponse) {
    return (
      <ChangePasswordErrorView
        errorName={errorResponse.errorName}
        onClose={props.onClose}
      />
    );
  }
  if (isTenantUser && !isTenantUserAccepted) {
    return (
      <TenantUserView
        email={props.userAccount.email}
        onAccept={async () => {
          const response = await startChangeTenantUserPassword();
          if (response.statusCode === 200) {
            setIsTenantUserAccepted(true);
          } else {
            setErrorResponse(JSON.parse(response.body));
          }
        }}
        onClose={props.onClose}
      />
    );
  }
  return (
    <ChangePasswordFormView
      isTenantUser={isTenantUser}
      onClose={props.onClose}
      onSave={isTenantUser ? updateTenantPassword : updateLocalPassword}
    />
  );
}
