import React, { Component } from 'react';
import {
  Form,
} from 'reactstrap';

import SCORING_USE_CASES from '../../config/scoringRequestUseCases';
import EmployerInformation from './EmployerInformation';
import IncomeInformation from './IncomeInformation';
import PersonInformation from './PersonInformation';
import LoanInformation from './LoanInformation';
import PersonalInformationBankingOnly from './PersonalInformationBankingOnly';
import { withUseModal } from '../../hooks/useModal';

class ApplicantFormGroup extends Component {
  validateFormBeforeProceeding = this.validateFormBeforeProceeding.bind(this);

  employerFormGroup = React.createRef();

  incomeFormGroup = React.createRef();

  applicantFormGroup = React.createRef();

  personBlockBankingOnlyInputGroup = React.createRef();

  loanFormGroup = React.createRef();

  showModal() {
    this.props.useModalProp.showModal({
      header: 'Problems with form.',
      body: 'We have detected incorrect input(s) from the form.\nPlease correct the form inputs and submit again.',
    });
  }

  // eslint-disable-next-line no-dupe-class-members
  validateFormBeforeProceeding() {
    if (this.props.requestUseCase === SCORING_USE_CASES.BANKING_ONLY) {
      const personBlockIsInvalid = this.personBlockBankingOnlyInputGroup.current.runValidation(true);
      const loanInfoBLockIsInvalid = this.loanFormGroup.current.runValidation();

      if (personBlockIsInvalid || loanInfoBLockIsInvalid) {
        this.showModal();
        return true;
      }

      return false;
    }

    const applicantSectionIsInvalid = this.applicantFormGroup.current.runValidation();
    const incomeSectionIsInvalid = this.incomeFormGroup.current?.runValidation();

    let employerSectionIsInvalid;
    const employmentEnabledKey = this.props.isCoApplicant ? 'coApplicantEmployment' : 'employment';

    if (this.props.enabledSections[employmentEnabledKey]) {
      employerSectionIsInvalid = this.employerFormGroup.current.runValidation();
    }

    if (employerSectionIsInvalid || applicantSectionIsInvalid || incomeSectionIsInvalid) {
      this.showModal();
      return true;
    }

    return false;
  }

  render() {
    const {
      isCoApplicant,
      clientConfig: {
        overrideApplicationFormFields = {},
      },
    } = this.props;
    const employmentEnabledKey = isCoApplicant ? 'coApplicantEmployment' : 'employment';
    const sectionTitle = isCoApplicant ? 'Co-Applicant' : 'Applicant';

    const renderApplicantBlock = () => {
      if (this.props.requestUseCase === SCORING_USE_CASES.BANKING_ONLY) {
        return (
          <>
            <PersonalInformationBankingOnly
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...this.props}
              useCase={this.props.requestUseCase}
              dataTreeKey="applicant"
              ref={this.personBlockBankingOnlyInputGroup}
              // fieldIdPrefix={this.props.fieldIdPrefix}
              highlightEssentialInputs
              allowMobileInvites={this.props.allowMobileInvites}
              history={this.props.history}
            />
            <LoanInformation
              useCase={this.props.requestUseCase}
              ref={this.loanFormGroup}
              dataTreeKey="loanInfo"
              hidePrincipalAmountField
              requireCustomerId
              requireLoanId
              enabledFeatures={this.props.enabledFeatures}
            />
          </>
        );
      }

      return (
        <>
          <PersonInformation
            title={sectionTitle}
            highlightEssentialInputs
            ref={this.applicantFormGroup}
            fieldIdPrefix={isCoApplicant ? 'co' : ''}
            allowMobileInvites={isCoApplicant ? false : this.props.allowMobileInvites}
            history={this.props.history}
            allowEmptyEmails={this.props.allowEmptyEmails}
            requireAddressInForms={this.props.requireAddressInForms}
            requireSsnInForms={this.props.requireSsnInForms}
            requireDobInForms={this.props.requireDobInForms}
            region={this.props.clientConfig.jurisdiction.country}
          />
          {
            this.props.enabledSections[employmentEnabledKey] && !isCoApplicant
              ? (
                <EmployerInformation
                  ref={this.employerFormGroup}
                  highlightEssentialInputs
                  dataTreeKey="employer"
                  overrides={overrideApplicationFormFields.employer || {}}
                  region={this.props.clientConfig.jurisdiction.country}
                />
              )
              : null
          }
          {
            !isCoApplicant && (
              <IncomeInformation
                ref={this.incomeFormGroup}
                highlightEssentialInputs
                dataTreeKey="income"
                overrides={overrideApplicationFormFields.income || {}}
              />
            )
          }
        </>
      );
    };

    return (
      <Form className="auto-loan-container">
        {renderApplicantBlock()}
      </Form>
    );
  }
}

export default withUseModal(ApplicantFormGroup);
