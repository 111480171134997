import { useMeasure } from '@react-hookz/web';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import React, { useMemo } from 'react';
import styles from '../../IncomeSourcesCard.module.scss';
import { colors, createScaleFont } from '../chart-lib';

const scaleFont = createScaleFont(800);

const getChartData = (income, transactionCutoffDate, cutoff) => {
  if (!income.mean_balances_with_offsets) {
    return { series: [], maxYValue: 0 };
  }

  const dataPoints = Object.entries(income.mean_balances_with_offsets).map(([key, value]) => {
    const [day, averageGroup] = key.split(/_/).slice(-2);
    return {
      x: parseInt(day, 10),
      y: value,
      cutoff: averageGroup,
    };
  });

  const filteredDataPoints = dataPoints.filter((dataPoint) => dataPoint.cutoff === cutoff);
  const maxYValue = Math.max(...filteredDataPoints.map(({ y }) => y));

  return {
    series: [{
      type: 'line',
      name: income.description.toUpperCase(),
      data: filteredDataPoints.map(({ x, y }) => [x, y]),
    }],
    maxYValue,
  };
};

const getChartOptions = (series, maxYValue, componentSize) => {
  const xValues = series[0]?.data.map(([x]) => x) || [];
  const maxYValueRounded = Math.ceil(maxYValue / 1000) * 1000;

  return {
    title: {
      text: 'Average End of Day Balance after Payday',
      textStyle: {
        fontSize: scaleFont(14, componentSize),
      },
    },
    grid: {
      right: 10,
      left: '8%',
      bottom: 30,
    },
    color: colors,
    xAxis: {
      axisTick: {
        alignWithLabel: true,
        customValues: xValues,
      },
      axisLabel: {
        fontSize: scaleFont(12, componentSize),
        formatter: (value) => (xValues.includes(value) ? value.toFixed(0) : ''),
      },
    },
    yAxis: {
      type: 'value',
      max: maxYValueRounded,
      axisLabel: {
        fontSize: scaleFont(12, componentSize),
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        label: {
          formatter: ({ value }) => (value === 0 ? 'Pay day' : `${value.toFixed(0)} day${value === 1 ? '' : 's'} after`),
        },
      },
      textStyle: {
        fontSize: scaleFont(10, componentSize),
      },
      valueFormatter: (value) => value.toFixed(2),
    },
    series: series.map((line) => ({
      ...line,
      symbolSize: 0,
      animationDuration: 250,
    })),
  };
};

function AvgEodBalanceChart({
  income, echarts, transactionCutoffDate, cutoff,
}) {
  const [componentSize, measureRef] = useMeasure();
  const rendered = !!componentSize?.width;

  const { series, maxYValue } = useMemo(
    () => getChartData(income, transactionCutoffDate, cutoff),
    [income, transactionCutoffDate, cutoff],
  );

  const options = useMemo(
    () => getChartOptions(series, maxYValue, componentSize),
    [series, maxYValue, componentSize],
  );

  if (!income) {
    return null;
  }

  return (
    <div ref={measureRef}>
      {rendered && (
        <ReactEChartsCore
          echarts={echarts}
          option={options}
          className={styles.chartSize}
          lazyUpdate
        />
      )}
    </div>
  );
}

export default AvgEodBalanceChart;
