import dot from 'dot';
import SITE_PATHS from '../../config/sitePaths';
import { LOGOUT_URL } from '../../config/console-configuration';

const buildSignOutUrl = () => {
  if (!LOGOUT_URL) return SITE_PATHS.SIGNOUT;
  const template = dot.template(LOGOUT_URL);
  return template({
    consoleSignOutUrl: [window.location.origin, SITE_PATHS.SIGNOUT].join(''),
  });
};

export default function Logout() {
  window.location.href = buildSignOutUrl();
  return null;
}
