import React from 'react';
import bankingReportFields from '../../../../config/banking-report-fields';
import { bankingReportField } from '../../../../utils/display-field';
import InputItemRow from '../../../../components/ScoringRequestInputSummary/InputItemRow';
import TransactionsTable from '../../BankingInfoCard/TransactionsTable';
import styles from './AccountSection.module.scss';

const sortOrder = Object.keys(bankingReportFields)
  .reduce((result, current, index) => ({ ...result, [current]: index }), {});

const sortBankingFields = (a, b) => (sortOrder[a.key] > sortOrder[b.key] ? 1 : -1);

const renderSectionTitle = (title, transitNumber, accountNumber) => (
  <h5 className="_lr-hide">
    {
      transitNumber
        ? `${title} for ${transitNumber}-${accountNumber}`
        : `${title} for ${accountNumber}`
    }
  </h5>
);

const getCutoffDate = (startDate, cutoffInDays) => {
  const result = new Date(startDate);
  result.setDate(result.getDate() - cutoffInDays);
  return result;
};

const byCutoffDate = (cutoffDate) => ({ date }) => (new Date(date).getTime() >= cutoffDate.getTime());

const getTransactions = (account, transactionFilter, additionalTransactions) => (
  [].concat(account.transactions)
    .concat(additionalTransactions?.[account.id])
    .filter((x) => !!x)
    .filter(byCutoffDate(getCutoffDate(account.transactionCutoffDate, transactionFilter)))
);

const renderItemRows = (data, isRootObject) => Object.keys(data)
  .map((key) => (bankingReportField(key)))
  .filter((field) => !field.hide)
  .sort(sortBankingFields)
  .map((field) => {
    const { key } = field;
    if (!isRootObject || !data[key]?.length || typeof data[key] === 'object') {
      return null;
    }

    if (typeof data[key] === 'object') {
      return renderItemRows(data[key]);
    }
    const displayValue = Array.isArray(data[key]) ? data[key].toString() : field.format(data[key]);

    return <InputItemRow label={field.label} key={key} value={displayValue} />;
  })
  .filter((i) => !!i);

const renderAccountAttributesList = (account) => {
  const itemRows = renderItemRows(account, true);
  const halfCount = Math.ceil(itemRows.length / 2);

  return (
    <>
      <div className={styles.basicAccountInfoWrapper}>
        { itemRows.slice(0, halfCount) }
      </div>
      <div className={styles.basicAccountInfoWrapper}>
        { itemRows.slice(halfCount) }
      </div>
    </>
  );
};
const renderAccount = (account, transactionFilter, additionalTransactions = []) => (
  <React.Fragment key={account.id || account.Id}>
    <section className={[styles.accountSection, '_lr-hide'].join(' ')}>
      { renderSectionTitle('General Information', account.transitNO, account.accountNO !== null ? account.accountNO : account.id) }
      { renderAccountAttributesList(account) }
      {
        Object.keys(account).map((key) => {
          if (!key.match(/transactions/ig) && account[key] !== null && typeof account[key] === 'object') {
            if (Array.isArray(account[key])) return null;

            return (
              <div key={key} className={styles.otherAccountInfoWrapper}>
                <h5>
                  {`Account ${key}`}
                </h5>
                { renderItemRows(account[key]) }
              </div>
            );
          }

          return null;
        })
      }
    </section>
    <section className={styles.transactionSection}>
      <div className={styles.transactionsWrapper}>
        { renderSectionTitle('Account Transactions', account.transitNO, account.accountNO !== null ? account.accountNO : account.id) }
        <TransactionsTable
          transactionData={getTransactions(account, transactionFilter, additionalTransactions)}
          accountInfo={{
            accountId: account.id,
            accountIndex: account.accountIndex,
          }}
        />
      </div>
    </section>
  </React.Fragment>
);

export default function AccountSection(props) {
  return props.accounts.map(
    (account) => renderAccount(account, props.transactionFilter, props.additionalTransactions),
  );
}
