import * as echarts from 'echarts/core';
import React, { useMemo } from 'react';
import {
  Collapse,
} from 'reactstrap';
import ExpandCollapseIcon from '../../../../components/ExpandCollapseIcon';
import categoryLabels from '../../../../config/banking-category-labels';
import capitalize from '../../../../utils/capitalize';
import getSubCategories from '../../../../utils/get-banking-sub-categories';
import getMonthlyIncome from '../get-monthly-income';
import styles from '../IncomeSourcesCard.module.scss';
import AvgEodBalanceChart from '../IncomeSourcesChart/AvgEodBalanceChart/AvgEodBalanceChartView';
import IncomeTransactionsTable from '../IncomeTransactionsTable';
import renderMonthlyAmount from '../renderMonthlyAmount';

const formatPayFrequency = (payFrequency) => {
  if (!payFrequency) {
    return 'Irregular/Unknown';
  }
  return payFrequency.charAt(0).toUpperCase() + payFrequency.slice(1);
};

const payFrequencyToColor = (payFrequency) => {
  if (!payFrequency) {
    return 'danger';
  }
  if (payFrequency.startsWith('days')) {
    return 'warning';
  }
  return 'success';
};

function StabilityIndicator({ stability }) {
  let color = 'danger';
  if (stability === 'high') {
    color = 'success';
  } else if (stability === 'medium') {
    color = 'warning';
  }
  return (
    <span className={`text-${color}`}>
      {capitalize(stability)}
    </span>
  );
}

function SubCategory({ subCategoryList }) {
  const [currentCategory, ...subCategories] = subCategoryList;

  const currentCategoryLabel = categoryLabels[currentCategory];
  if (!currentCategoryLabel) {
    console.error(`Unknown subcategory: ${currentCategory}`);
    return null;
  }

  const hasSubCategories = subCategories.length > 0;

  return (
    <div className="d-inline">
      {currentCategoryLabel}
      {hasSubCategories && (
        <div className="ml-2">
          <span>{'→ '}</span>
          <SubCategory subCategoryList={subCategories} />
        </div>
      )}
    </div>
  );
}

const getAvailableCutoffs = (income) => {
  const cutoffSet = new Set(Object.keys(income?.mean_balances_with_offsets ?? {}).map((key) => {
    const [averageGroup] = key.split(/_/).slice(-1);
    return averageGroup;
  }));
  return Array.from(cutoffSet);
};

function CutoffButton({
  days, lastNDays, setLastNDays, availableCutoffs, incomeKey,
}) {
  if (!availableCutoffs.includes(days)) {
    return null;
  }

  const id = `${incomeKey}-cutoff-${days}`;
  return (
    <label
      className={`btn ${styles.cursorPointer} ${days === lastNDays ? 'btn-primary' : 'btn-outline-primary '}`}
      htmlFor={id}
    >
      <input type="radio" name="options" id={id} onClick={() => setLastNDays(days)} />
      {' '}
      {capitalize(days)}
    </label>
  );
}

export default function IncomeSource({
  sourceData, selected, toggleSelected, chartData, transactionCutoffDate,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [cutoff, setCutoff] = React.useState('all');

  const monthlyNetIncome = getMonthlyIncome(sourceData, false);
  const monthlyGrossIncome = getMonthlyIncome(sourceData, true, 0);

  const availableCutoffs = useMemo(() => getAvailableCutoffs(chartData), [chartData]);

  return (
    <div className={`card my-2 ${selected ? styles.selectedCard : ''}`}>
      <div className={`row card-body py-3 ${styles.clickable}`} onClick={toggleSelected} aria-hidden="true">
        <div className="col-auto d-flex align-items-center">
          <input
            type="checkbox"
            checked={selected}
            className={`${styles.selectIncomeSourceCheckbox}`}
            readOnly
          />
        </div>
        <div className="col text-left">
          <div className="row ml-0">
            <div className={styles.incomeTitle}>{sourceData.description.toUpperCase()}</div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-4">Income Category:</div>
                <div className="col">
                  {sourceData.category ? <SubCategory subCategoryList={getSubCategories(sourceData.category)} /> : 'Unknown'}
                </div>
              </div>
              <div className="row">
                <div className="col-4">Stability:</div>
                <div className="col-6">
                  <StabilityIndicator stability={sourceData.incomeSourceStabilityLabel ?? 'Unknown'} />
                </div>
              </div>
              <div className="row">
                <div className="col-4">Frequency:</div>
                <div className="col-6">
                  <span className={`text-${payFrequencyToColor(sourceData.payFrequency)}`}>
                    {formatPayFrequency(sourceData.payFrequency)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">Net Income:</div>
                <div className={`col-6 ${styles.incomeTitle}`}>
                  {monthlyNetIncome <= 0 ? <span className="font-weight-bold">Unknown</span> : (
                    renderMonthlyAmount(monthlyNetIncome)
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-4">Est. Gross Income:</div>
                <div className="col-6">
                  {(() => {
                    if (monthlyGrossIncome <= 0) {
                      return monthlyNetIncome <= 0 ? 'Unknown' : 'Unknown (Using Net)';
                    }
                    return renderMonthlyAmount(monthlyGrossIncome);
                  })()}
                </div>
              </div>
              {sourceData.alerts.length > 0 && (
                <div className="row">
                  <div className="col-4">Alerts:</div>
                  <div className="col-6">
                    {sourceData.alerts.map((alert) => (
                      <div
                        key={alert}
                        className="text-left text-danger"
                      >
                        {alert}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-auto d-flex align-items-center"
          onClick={(e) => {
            e.stopPropagation();
            setIsCollapsed(!isCollapsed);
          }}
          aria-hidden="true"
        >
          <ExpandCollapseIcon
            isCollapsed={isCollapsed}
            toggleIsCollapsed={() => setIsCollapsed(!isCollapsed)}
          />
        </div>
      </div>
      <Collapse isOpen={!isCollapsed} className={`${styles.printable} mx-4 mb-4`}>
        <hr className="mt-0" />
        <div className="row">
          <div className="col">
            <div className="row pb-2">
              <div className="col">
                <div className="row">
                  <div className="col-auto">First Occurrence:</div>
                  <div className="col-auto">
                    {sourceData.earliestDate}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">Last Occurrence:</div>
                  <div className="col-auto">
                    {sourceData.latestDate}
                  </div>
                </div>
              </div>
              <div className="col text-right">
                <div className="row">
                  <div className="col-auto">Next payment:</div>
                  <div className="col-auto">
                    <span className={sourceData.nextPaymentDate}>
                      {sourceData.nextPaymentDate ?? 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <IncomeTransactionsTable transactions={sourceData.transactions ?? []} />
          </div>
          <div className="col-6">
            <div className="row d-flex justify-content-end mr-2">
              <div className="btn-group btn-group-toggle justify-content-end" data-toggle="buttons">
                <CutoffButton
                  days="30"
                  lastNDays={cutoff}
                  setLastNDays={setCutoff}
                  availableCutoffs={availableCutoffs}
                  incomeKey={chartData.key}
                />
                <CutoffButton
                  days="90"
                  lastNDays={cutoff}
                  setLastNDays={setCutoff}
                  availableCutoffs={availableCutoffs}
                  incomeKey={chartData.key}
                />
                <CutoffButton
                  days="180"
                  lastNDays={cutoff}
                  setLastNDays={setCutoff}
                  availableCutoffs={availableCutoffs}
                  incomeKey={chartData.key}
                />
                <CutoffButton
                  days="270"
                  lastNDays={cutoff}
                  setLastNDays={setCutoff}
                  availableCutoffs={availableCutoffs}
                  incomeKey={chartData.key}
                />
                <CutoffButton
                  days="all"
                  lastNDays={cutoff}
                  setLastNDays={setCutoff}
                  availableCutoffs={availableCutoffs}
                  incomeKey={chartData.key}
                />
              </div>
            </div>
            <AvgEodBalanceChart
              echarts={echarts}
              income={chartData}
              cutoff={cutoff}
              transactionCutoffDate={transactionCutoffDate}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}
