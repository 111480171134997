import React, {
  useCallback, useEffect, useMemo, useState,
  Suspense,
} from 'react';
import {
  CardBody,
  Collapse,
} from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IncomeSourcesCard.module.scss';
import { useModal } from '../../../hooks/useModal';
import CollapsibleCard from '../CollapsibleCard';
import ExpandCollapseIcon from '../../../components/ExpandCollapseIcon';
import api from '../../../services/api';
import getMonthlyIncome from './get-monthly-income';
import HelpMessage from './HelpMessage';
import IncomeSource from './IncomeSource';
import renderMonthlyAmount from './renderMonthlyAmount';
import OtherCreditSource from './OtherCreditSource';
import Loader from '../../../components/Loader';

const IncomeSourcesChart = React.lazy(() => import('./IncomeSourcesChart'));

const blankToggleText = '\xa0\xa0\xa0';

function SectionHeader({ title }) {
  return (
    <div className="row">
      <div className="col">
        <h4 className="text-left m-1 mt-4">{title}</h4>
      </div>
    </div>
  );
}

function StatInfoSummary({
  value, sources, useGrossIncome,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggleIsCollapsed = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  return (
    <div>
      <h6>
        <div className={styles.lineCheckboxLabelWrapper}>
          <div className="d-flex align-items-center h5">
            <button type="button" onClick={toggleIsCollapsed} className="border-0 bg-transparent">
              {renderMonthlyAmount(value)}
            </button>
            <div className="d-inline mt-n1">
              <ExpandCollapseIcon
                isCollapsed={isCollapsed}
                toggleIsCollapsed={toggleIsCollapsed}
              />
            </div>
          </div>
        </div>
      </h6>
      <Collapse isOpen={!isCollapsed} className={styles.printable}>
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">Source</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {sources.map((sourceData) => (
              <tr key={sourceData.key} className={styles.selectedIncomeSource}>
                <th>
                  {sourceData.description.toUpperCase()}
                </th>
                <th>
                  $
                  {Math.round(getMonthlyIncome(sourceData, useGrossIncome))}
                </th>
              </tr>
            ))}
            <tr>
              <td>Total</td>
              <td>
                $
                {Math.round(value)}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
}

const addSourceKey = (sourceData) => ({ ...sourceData, key: sourceData.id ?? sourceData.description });

export default function IncomeSourcesCardView({
  clientReports, currentEmployment, requestId, isUat,
}) {
  const { products } = clientReports?.[0] ?? {};

  const bank844Data = products?.find((product) => product.productCode === 'BANK-844');
  const [bankingDataAvailable, setBankingDataAvailable] = useState(false);

  const allIncome = useMemo(() => (bank844Data?.bankingIncomeSources ?? []).map(addSourceKey) || [], [bank844Data]);
  const otherCredits = useMemo(() => (bank844Data?.otherCreditSources ?? []).map(addSourceKey) || [], [bank844Data]);

  const [chartIncomes, setChartIncomes] = useState([]);
  const [selectedSources, setSelectedSources] = useState({});
  const [allSources, setAllSources] = useState({});
  const [useGrossIncome, setUseGrossIncome] = useState(false);
  const [transactionCutoffDate, setTransactionCutoffDate] = useState(null);

  useEffect(() => {
    const getChartData = async () => {
      const { success, incomes, transactionCutoffDate: cutoffDate } = await api('GetIncomeChartData', {
        isUat,
        requestId,
      });
      if (!success) {
        console.error('Failed to get income chart data');
        return;
      }
      const incomesWithKey = incomes?.map(addSourceKey) ?? [];
      setChartIncomes(incomesWithKey);
      const lineEntries = [
        ['HighConfidenceIncomeLine', true],
        ['HighConfidenceAndOtherIncomeLine', true],
      ];
      const initialSelectedSources = Object.fromEntries([
        ...incomesWithKey.map((source) => [source.key, true]),
        ...allIncome.map((source) => [source.key, true]),
        ...otherCredits.map((source) => [source.key, false]),
        ...lineEntries,
      ]);
      setSelectedSources(initialSelectedSources);
      setAllSources(initialSelectedSources);
      setTransactionCutoffDate(cutoffDate);
      setBankingDataAvailable(true);
    };
    if (bank844Data) {
      getChartData();
    }
  }, [bank844Data, allIncome, otherCredits, isUat, requestId, setSelectedSources]);

  const monthlyIncomesReducer = useCallback((acc, source) => acc + getMonthlyIncome(source, useGrossIncome), [useGrossIncome]);

  const { showModal } = useModal();

  const toggleSelected = useCallback((stateUpdateFunc) => (key) => {
    stateUpdateFunc((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  if (!bank844Data || bank844Data.productStatus === 'error' || !bankingDataAvailable) return null;

  const showHelp = () => {
    showModal({
      header: 'Help',
      body: <HelpMessage />,
      className: styles.helpModal,
    });
  };

  const isSelectedFiltered = (source) => selectedSources[source.key];

  const selectedIncome = allIncome.filter(isSelectedFiltered);

  const incomeTotal = selectedIncome.reduce(monthlyIncomesReducer, 0);

  const selfReportedGrossIncome = currentEmployment?.monthlyIncomeGross ?? null;
  const selfReportedNetIncome = currentEmployment?.monthlyIncomeNet ?? null;
  const hasSelfReportedIncome = selfReportedGrossIncome !== null || selfReportedNetIncome !== null;
  const findChartIncome = (key) => chartIncomes.find((income) => income.description === key);

  return (
    <CollapsibleCard title="Income Sources" collapseKey="incomeSources">
      <CardBody>
        {bank844Data.productStatus === 'notAvailable' ? (
          <div>No income sources found</div>
        ) : (
          <>
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-center align-items-center mt-4 position-relative">
                  <h3 className="m-1 text-primary">All Income</h3>
                  <button
                    className={`bg-transparent border-0 m-0 p-1 position-absolute ${styles.helpButton}`}
                    aria-label="Income Sources Help"
                    type="button"
                    onClick={showHelp}
                  >
                    <FontAwesomeIcon
                      icon="question-circle"
                      color="grey"
                      size="lg"
                    />
                  </button>
                </div>
                <Suspense fallback={<Loader />}>
                  <IncomeSourcesChart
                    incomes={chartIncomes}
                    selectedSources={allSources}
                    incomeTotal={incomeTotal}
                    useGrossIncome={useGrossIncome}
                    toggleSelected={toggleSelected(setAllSources)}
                  />
                </Suspense>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 className="text-left m-1 mt-4 text-primary text-center">Recent Income & Credit Sources</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-9 col-xl-10">
                <SectionHeader title="Recent Income:" />
                {allIncome.map((sourceData) => (
                  <IncomeSource
                    key={sourceData.key}
                    sourceData={sourceData}
                    chartData={findChartIncome(sourceData.key)}
                    transactionCutoffDate={transactionCutoffDate}
                    selected={!!selectedSources[sourceData.key]}
                    toggleSelected={() => toggleSelected(setSelectedSources)(sourceData.key)}
                  />
                ))}
                <SectionHeader title={otherCredits.length ? 'Other Credits:' : 'No Other Credits'} />
                {otherCredits.map((sourceData) => (
                  <OtherCreditSource
                    key={sourceData.key}
                    sourceData={sourceData}
                  />
                ))}
              </div>
              <div className="col-3 col-xl-2 px-2">
                <div className={`text-left mt-5 pt-3 ${styles.stickyStatsBox}`}>
                  <div className="text-center rounded border pt-3 mb-3">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="h4" htmlFor="grossNetIncomeToggle">Net</label>
                    <div className="d-inline mt-n2">
                      <Toggle
                        id="grossNetIncomeToggle"
                        active={useGrossIncome}
                        on={blankToggleText}
                        off={blankToggleText}
                        size="xs"
                        onstyle="success"
                        handlestyle="default"
                        offstyle="custom"
                        className="mt-n2 mx-2"
                        onClick={(value) => setUseGrossIncome(value)}
                      />
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="h4" htmlFor="grossNetIncomeToggle">Gross</label>
                  </div>
                  <h4>Selected Income</h4>
                  <StatInfoSummary
                    value={incomeTotal}
                    sources={selectedIncome}
                    useGrossIncome={useGrossIncome}
                  />
                  <div className="mb-4">
                    {hasSelfReportedIncome ? (
                      <>
                        <h4>Self-reported Income</h4>
                        {selfReportedGrossIncome && (
                          <div className="ml-2">
                            <h5>Gross Income:</h5>
                            <h5>
                              {renderMonthlyAmount(selfReportedNetIncome)}
                            </h5>
                          </div>
                        )}
                        {selfReportedNetIncome && (selfReportedNetIncome !== selfReportedGrossIncome) && (
                          <div className="ml-2">
                            <h5>Net Income:</h5>
                            <h5>
                              {renderMonthlyAmount(selfReportedNetIncome)}
                            </h5>
                          </div>
                        )}
                      </>
                    ) : <h4>Self-reported Income Not Provided</h4>}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </CardBody>
    </CollapsibleCard>
  );
}
