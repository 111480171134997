import React, { lazy, Suspense } from 'react';
import { CardBody } from 'reactstrap';
import CollapsibleCard from '../CollapsibleCard';
import styles from './BalanceCard.module.scss';
import dollarFormatter from './dollar-formatter';
import Loader from '../../../components/Loader';

const BalanceChart = lazy(() => import('./BalanceChart'));

function DaysButton({ days, lastNDays, setLastNDays }) {
  return (
    <button
      type="button"
      className={`w-100 btn ${days === lastNDays ? 'btn-primary' : 'btn-outline-primary '}`}
      onClick={() => setLastNDays(days)}
    >
      {days}
    </button>
  );
}

function SummaryStat({ title, value }) {
  return (
    <h4>
      {title}
      <br />
      {dollarFormatter(value, true)}
    </h4>
  );
}

export default function ({ balanceData }) {
  const [lastNDays, setLastNDays] = React.useState(365);

  if (!balanceData?.available) {
    return null;
  }

  const {
    currentBalance,
    dailyAggregatedBalances,
    allDeposits,
  } = balanceData;
  const dailyAggregatedBalancesArray = Object.entries(dailyAggregatedBalances).sort().slice(-lastNDays);
  const totalDepositsArray = Object.entries(allDeposits).sort().slice(-lastNDays);
  const averageBalance = dailyAggregatedBalancesArray.reduce(
    (acc, [, balance]) => acc + balance,
    0,
  ) / dailyAggregatedBalancesArray.length;
  const totalDeposits = Object.values(totalDepositsArray).reduce(
    (acc, [, deposit]) => acc + deposit,
    0,
  );

  return (
    <CollapsibleCard title="Banking Balance" collapseKey="bankingBalance">
      <CardBody>
        <div className={`row ${styles.topDiv}`}>
          <div className="col-4">
            <SummaryStat
              title="Current Balance:"
              value={currentBalance}
            />
          </div>
          <div className="col-4">
            <SummaryStat
              title={`Average Balance (${lastNDays}\xa0days):`}
              value={averageBalance}
            />
          </div>
          <div className="col-4">
            <SummaryStat
              title={`Total Deposits (${lastNDays}\xa0days):`}
              value={totalDeposits}
            />
          </div>
        </div>
        <div className="row mx-4">
          <div className="col-10 col-xl-11 pr-0">
            <Suspense fallback={<Loader />}>
              <div className="mx-n5 mt-n5">
                <BalanceChart
                  lastNDays={lastNDays}
                  dailyAggregatedBalancesArray={dailyAggregatedBalancesArray}
                />
              </div>
            </Suspense>
          </div>
          <div className="col-2 col-xl-1 pl-0">
            <h6>Number of Days</h6>
            <DaysButton
              days={30}
              lastNDays={lastNDays}
              setLastNDays={setLastNDays}
            />
            <DaysButton
              days={90}
              lastNDays={lastNDays}
              setLastNDays={setLastNDays}
            />
            <DaysButton
              days={365}
              lastNDays={lastNDays}
              setLastNDays={setLastNDays}
            />
          </div>
        </div>
      </CardBody>
    </CollapsibleCard>
  );
}
