import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';

import InputFieldFeedback from '../InputFieldFeedback';
import StatesAndProvincesOptions from '../../ScoringRequestForm/StatesAndProvincesOptions';
import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import { addressEditModes } from '../../../constants';

import styles from './AddressForm.module.scss';

const validator = new FormValidator(validationConfig);
const emptyFormState = {
  name: '',
  storeNumber: '',
  address: '',
  city: '',
  postalCode: '',
  state: '',
  phoneNumber: '',
  type: '',
  amsLotId: '',
};

function didTheLocationChange(initialLocation, newLocations) {
  return JSON.stringify(initialLocation) !== JSON.stringify(newLocations);
}

export default function (props) {
  const initialFormState = props.selectedAddress || emptyFormState;
  const [validation, setValidation] = useState({ isInvalid: false });
  const [selectedAddress, setAddress] = useState({ ...initialFormState });
  const modalOpen = !!(props.addOrEdit);
  const modalTitle = `${props.addOrEdit === addressEditModes.ADD ? 'Add New' : 'Edit Existing'} Address`;
  const confirmButtonLabel = props.addOrEdit === addressEditModes.ADD ? 'Add' : 'Update';
  const {
    name,
    storeNumber,
    address,
    city,
    postalCode,
    state,
    phoneNumber,
    type,
    amsLotId,
  } = selectedAddress;

  const { loading, error } = props.editingProgress;

  if (!props.hasAmsIntegration) {
    validator.validations = validator.validations.filter((rule) => rule.field !== 'amsLotId');
  }

  const highlightFieldIfInvalid = (key) => {
    if (validation[key] && validation[key].isInvalid) {
      return 'is-invalid';
    }

    return '';
  };

  const renderAMSLotIdInput = () => {
    if (!props.hasAmsIntegration) {
      return null;
    }

    return (
      <>
        <Label>AMS Lot ID*</Label>
        <Input
          className={highlightFieldIfInvalid('amsLotId')}
          type="text"
          name="amsLotId"
          id="amsLotId"
          onChange={(event) => setAddress({ ...selectedAddress, amsLotId: event.target.value })}
          defaultValue={amsLotId}
        />
        <InputFieldFeedback validation={validation.amsLotId} />
      </>
    );
  };

  const onSaveClicked = () => {
    if (!didTheLocationChange(initialFormState, selectedAddress)) return;
    let inputValidation = validator.validate(selectedAddress);

    const storeNumberDuplicate = props.locations.filter((locations) => locations.storeNumber === selectedAddress.storeNumber);
    if (storeNumberDuplicate.length && (props.addOrEdit === addressEditModes.ADD)) {
      inputValidation = {
        ...inputValidation,
        isInvalid: true,
        storeNumber: {
          isInvalid: true,
          message: `Store Number already in use by location ${storeNumberDuplicate[0].name}.`,
        },
      };
    }
    setValidation(inputValidation);

    if (inputValidation.isInvalid) {
      return;
    }

    const addressNameAlreadyUsed = props.existingLocationNames.find((locationName) => {
      if (locationName === initialFormState.name && props.addOrEdit === addressEditModes.EDIT) {
        return false;
      }

      return locationName === name;
    });

    if (addressNameAlreadyUsed) {
      setValidation({
        isInvalid: false,
        name: { isInvalid: true, message: 'Location name is already in use.' },
      });
      return;
    }

    props.onEditComplete(selectedAddress, props.addOrEdit);
  };

  const renderErrorMessage = (errorOccured) => {
    if (!errorOccured) {
      return null;
    }

    return (
      <span className={styles.errorMessage}>
        There is a problem saving this address.
        <br />
        Please try again in a moment.
      </span>
    );
  };

  return (
    <Modal isOpen={modalOpen} className={styles.addressForm}>
      <ModalHeader className={styles.header}>
        {modalTitle}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Location Name*</Label>
          <Input
            className={highlightFieldIfInvalid('name')}
            type="text"
            name="name"
            id="name"
            onChange={(event) => setAddress({ ...selectedAddress, name: event.target.value })}
            defaultValue={name}
          />
          <InputFieldFeedback validation={validation.name} />

          <Label>Store Number*</Label>
          <Input
            className={highlightFieldIfInvalid('storeNumber')}
            type="text"
            name="storeNumber"
            id="storeNumber"
            onChange={(event) => setAddress({ ...selectedAddress, storeNumber: event.target.value })}
            defaultValue={storeNumber}
          />
          <InputFieldFeedback validation={validation.storeNumber} />

          <Label>Street Address*</Label>
          <Input
            className={highlightFieldIfInvalid('address')}
            type="text"
            name="address"
            id="address"
            onChange={(event) => setAddress({ ...selectedAddress, address: event.target.value })}
            defaultValue={address}
          />
          <InputFieldFeedback validation={validation.address} />

          <Label>City*</Label>
          <Input
            className={highlightFieldIfInvalid('city')}
            type="text"
            name="city"
            id="city"
            onChange={(event) => setAddress({ ...selectedAddress, city: event.target.value })}
            defaultValue={city}
          />
          <InputFieldFeedback validation={validation.city} />

          <Label>Zip / Postal Code*</Label>
          <Input
            className={highlightFieldIfInvalid('postalCode')}
            type="text"
            name="postalCode"
            id="postalCode"
            onChange={(event) => setAddress({ ...selectedAddress, postalCode: event.target.value })}
            defaultValue={postalCode}
          />
          <InputFieldFeedback validation={validation.postalCode} />

          <Label>State / Province*</Label>
          <Input
            className={highlightFieldIfInvalid('state')}
            type="select"
            name="state"
            id="state"
            onChange={(event) => setAddress({ ...selectedAddress, state: event.target.value })}
            defaultValue={state}
          >
            <StatesAndProvincesOptions region={props.region} />
          </Input>
          <InputFieldFeedback validation={validation.state} />

          <Label>Phone Number</Label>
          <Input
            className={highlightFieldIfInvalid('phoneNumber')}
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            onChange={(event) => setAddress({ ...selectedAddress, phoneNumber: event.target.value })}
            defaultValue={phoneNumber}
          />
          <InputFieldFeedback validation={validation.phoneNumber} />

          <Label>Location Type</Label>
          <Input
            className={highlightFieldIfInvalid('type')}
            type="select"
            name="type"
            id="type"
            onChange={(event) => setAddress({ ...selectedAddress, type: event.target.value })}
            defaultValue={type}
          >
            <option value="">Please Select...</option>
            <option value="franchise">Franchise</option>
            <option value="independent">Independent</option>
          </Input>
          <InputFieldFeedback validation={validation.type} />

          { renderAMSLotIdInput() }
        </FormGroup>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        { renderErrorMessage(error) }
        <Button
          color="primary"
          className={styles.confirmButton}
          onClick={onSaveClicked}
          disabled={loading}
        >
          { loading ? <Spinner size="sm" color="white" /> : confirmButtonLabel }
        </Button>
        <Button
          color="secondary"
          onClick={() => props.setAddOrEdit(undefined)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
