const SITE_PATHS = {
  ROOT: '/',
  SETTINGS: '/settings',
  MANAGE_LOCATIONS: '/manage-locations',
  REPORTS: '/reports',
  NOTIFICATIONS: '/notifications',
  DOCUMENTATION_ACCOUNT_SWITCHING: '/documentation/accountSwitching',
  DOCUMENTATION_V1: '/documentation/v1',
  DOCUMENTATION_V2: '/documentation/v2',
  DOCUMENTATION_V3: '/documentation/v3',
  DOCUMENTATION_LOANSTATUS_API: '/documentation/loanStatusApi',
  DOCUMENTATION_EMBEDDED_SMARTCONSENT: '/documentation/embeddedSmartConsent',
  DOCUMENTATION_SMARTCONSENT_OVERVIEW: '/documentation/smartConsentOverview',
  BUREAU_API: '/documentation/bureauAPI',
  BANKING_API: '/documentation/bankingAPI',
  DATASOURCE_API: '/documentation/dataSourceApi',
  BILLING: '/billing',
  CHANGE_PASSWORD: '/change-password',
  SWAGGER_DOCUMENTATION: 'https://appservices-uat.trustscience.com/docs',
  AMS_FORM_START: '/ams-form',
  INSTALLMENT_FORM_START: '/installment-form',
  AUTO_LOAN_APPLICATION_FORM_START: '/auto-loan-form',
  SHORT_TERM_FORM_START: '/short-term-loan',
  BANKING_ONLY_FORM_START: '/banking-only',
  REQUEST_HISTORY: '/request-history',
  SCORING_REQUEST: {
    APPLICANT_INFO: '/applicant',
    EMPLOYMENT_INFO: '/employment',
    INCOME_INFO: '/income',
    COAPPLICANT_INFO: '/coapplicant',
    LOAN_DETAILS: '/loan-details',
    SCORING_REPORT: '/scoring-report',
    PRINT_PREVIEW: '/print-preview',
  },
  PERSON_DETAIL_DEMO_PAGE: '/public-search-beta',
  SCORING_REQUEST_DETAILS: '/scoring-request-details/:requestId',
  MANAGE_USERS: '/manage-users',
  MANAGE_INTEGRATIONS: '/manage-integrations',
  MANAGE_DEPLOYMENTS: '/manage-deployments',
  FLOW_BUILDER: '/flow-builder',
  RULE_BUILDER: '/rule-builder',
  RULE_BUILDER_CHAT: '/rule-builder/:chatId',
  TEST: '/settings?/test',
  EMAIL_VERIFICATION: '/email-verification',
  SIGNOUT: '/signout',
  LOGOUT: '/logout',
};

export const DOC_REDIRECTS = {
  DOCUMENTATION_ACCOUNT_SWITCHING: '/documentation/accountSwitching',
  DOCUMENTATION_V1: '/documentation/v1',
  DOCUMENTATION_V2: '/documentation/v2',
  DOCUMENTATION_V3: '/documentation/v3',
  DOCUMENTATION_LOANSTATUS_API: '/documentation/loanStatusApi',
  DOCUMENTATION_EMBEDDED_SMARTCONSENT: '/documentation/embeddedSmartConsent',
  DOCUMENTATION_SMARTCONSENT_OVERVIEW: '/documentation/smartConsentOverview',
  BUREAU_API: '/documentation/bureauAPI',
  BANKING_API: '/documentation/bankingAPI',
  DATASOURCE_API: '/documentation/dataSourceApi',
};


export const USE_CASE_TO_SITE_PATHS = {
  'Lending-Payday': SITE_PATHS.SHORT_TERM_FORM_START, // temporary
  'Lending-AUTO': SITE_PATHS.AUTO_LOAN_APPLICATION_FORM_START, // evaluate if form needs to be AMS
  'Lending-AUTO-Preapproval': SITE_PATHS.AUTO_LOAN_APPLICATION_FORM_START, // temporary
  'Lending-Installment': SITE_PATHS.INSTALLMENT_FORM_START,
  'Lending-Title': SITE_PATHS.INSTALLMENT_FORM_START,  // temporary
  'Lending-ShortTerm': SITE_PATHS.SHORT_TERM_FORM_START,
  'Lending-BankingOnly': SITE_PATHS.BANKING_ONLY_FORM_START,
};

export default SITE_PATHS;

export const NODE_NAMES = {
  NAVMENU: 'NAVMENU',
  USERMENU: 'USERMENU',
  SCORING_REQUEST: 'SCORING_REQUEST',
  SETTINGS: 'SETTINGS',
  REPORTS: 'REPORTS',
  REQUEST_HISTORY: 'REQUEST_HISTORY',
  DOCUMENTATION: 'DOCUMENTATION',
  DASHBOARD: 'DASHBOARD',
  FLOWBUILDER: 'FLOWBUILDER',
}

export const PARENT_NAV_SETTINGS = {

  SCORING_REQUEST: {
    name: NODE_NAMES.SCORING_REQUEST,
    label: 'Scoring',
    parentNavNode: NODE_NAMES.NAVMENU,
  },
  SETTINGS: {
    name: NODE_NAMES.SETTINGS,
    label: 'Settings',
    icon: 'cog',
    parentNavNode: NODE_NAMES.NAVMENU,
  },
  FLOWBUILDER: {
    name: NODE_NAMES.FLOWBUILDER,
    label: 'FlowBuilder',
    parentNavNode: NODE_NAMES.NAVMENU,
  },
  REPORTS: {
    name: NODE_NAMES.REPORTS,
    label: 'Reports',
    parentNavNode: NODE_NAMES.NAVMENU,
  },
  REQUEST_HISTORY: {
    name: NODE_NAMES.REQUEST_HISTORY,
    label: 'Request History',
    parentNavNode: NODE_NAMES.NAVMENU,
  },
  DOCUMENTATION: {
    name: NODE_NAMES.DOCUMENTATION,
    label: 'Documentation',
    icon: 'book',
    parentNavNode: NODE_NAMES.NAVMENU,

  },
  DASHBOARD: {
    name: NODE_NAMES.DASHBOARD, 
    label: 'Dashboard', 
    path: SITE_PATHS.ROOT, 
    parentNavNode: NODE_NAMES.NAVMENU
  },
};


const USERMENU_NAV_ORDER = [];

const NAVMENU_NAV_ORDER = [
  PARENT_NAV_SETTINGS.DASHBOARD.name,
  PARENT_NAV_SETTINGS.REPORTS.name,
  PARENT_NAV_SETTINGS.FLOWBUILDER.name,
  PARENT_NAV_SETTINGS.REQUEST_HISTORY.name,
  PARENT_NAV_SETTINGS.SCORING_REQUEST.name,
  PARENT_NAV_SETTINGS.DOCUMENTATION.name, 
  PARENT_NAV_SETTINGS.SETTINGS.name,
];

export const getNavOrder = (navNode, displayLocation) => {
  if (displayLocation === NODE_NAMES.NAVMENU) {
    const navOrder = NAVMENU_NAV_ORDER.indexOf(navNode);
    return navOrder >= 0 ? navOrder + 1 : 9;
  }
  if (displayLocation === NODE_NAMES.USERMENU) {
    return USERMENU_NAV_ORDER.indexOf(navNode) + 1;
  }
  return 9;

}
