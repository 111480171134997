import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import React from 'react';

const messageLookup = {
  UserNotFoundException: 'Your email does not match an active user.',
  ExpiredCodeException: 'The confirmation code has expired.',
  CodeMismatchException: 'The confirmation code is incorrect.',
  LimitExceededException: 'You have exceeded the number of attempts to change your password.',
};

export default function ChangePasswordErrorView(props) {
  const {
    errorName,
  } = props;
  const message = messageLookup[errorName] ?? 'An error occurred while changing your password.';
  return (
    <Modal isOpen>
      <ModalHeader>
        Error Changing Password
      </ModalHeader>
      <ModalBody>
        <div>
          { message }
          &nbsp;Please try again later. If the problem persists please contact your account administrator.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          id="ok"
          color="secondary"
          onClick={props.onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
