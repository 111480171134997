import React from 'react';

import styles from './AllowedDataSourceList.module.scss';

const displayDataSource = {
  lexisnexis: 'Lexis Nexis Risk View',
  lninstantid: 'Lexis Nexis Instant Id',
  lnfraudpoint: 'Lexis Nexis Fraud Point',
  telecheckmicroindicators: 'Fiserv Telecheck Micro Indicators',
  telecheckaccountvalidation: 'Fiserv Telecheck Account Validation',
  telecheckconfidencescore: 'Fiserv Telecheck Confidence Score',
  kevari: 'Kevari Data Network (KDNPlus)',
};

const renderNoDataSourcesEnabled = () => (
  <div>
    No data sources are enabled on your account at the moment.
  </div>
);

const renderDataSource = (key) => (
  <li key={key}>
    <div className={styles.dataSourceKey}>{key}</div>
    <div className={styles.dataSourceLabel}>{displayDataSource[key]}</div>
  </li>
);

export default function AllowedDataSourceList(props) {
  const { clientConfig: { dataSourceApi } } = props;
  if (!dataSourceApi?.enabled) return null;
  if (!dataSourceApi.allowedDataSources?.length) return renderNoDataSourcesEnabled();
  return (
    <div className={styles.allowedDataSourceList}>
      <div>Your currently enabled data sources are:</div>
      <ul>
        {
          dataSourceApi.allowedDataSources
            .map((k) => k.toLowerCase())
            .filter((k) => !!displayDataSource[k])
            .map(renderDataSource)
        }
      </ul>
    </div>
  );
}
