import React from 'react';
import {
  Container,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import StatesAndProvincesOptions from '../StatesAndProvincesOptions';

import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

const formGroupFieldNames = [
  'address', 'city', 'postalCode', 'state', 'phoneNumber',
  'employmentType', 'jobTitle',
  'durationAtEmployer',
];

class EmployerInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;

    if (props.highlightEssentialInputs) {
      super.skipValidationOnGroupIfEmpty = false;
    }
    this.togglePaymentsPerYearToolTip = this.togglePaymentsPerYearToolTip.bind(this);
    const requireFields = Object.keys(props.overrides)
      .filter((k) => props.overrides[k].required)
      .map((k) => ({
        field: k,
        method: 'isEmpty',
        validWhen: false,
        message: 'A value is required.',
      }));
    this.validator = new FormValidator(validationConfig.concat(requireFields));
  }

  togglePaymentsPerYearToolTip() {
    this.setState((prevState) => (
      {
        paymentsPerYearToolTipOpen: !prevState.paymentsPerYearToolTipOpen,
      }
    ));
  }

  render() {
    const highlightDurationAtEmployer = this.props.overrides?.durationAtEmployer?.required
      && this.props.highlightEssentialInputs;
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <div className="employer-input-group">
              <h4>Employment</h4>
              <div className="form-group-wrapper">
                <Container>
                  <FormGroup>
                    <Label htmlFor="employerAddress">
                      Address
                    </Label>
                    <SanitizedTextInput
                      className={this.state.addressFieldInvalid ? 'is-invalid' : ''}
                      name="address"
                      id="employerAddress"
                      type="text"
                      defaultValue={previousState.address || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="employerCity">
                      City
                    </Label>
                    <SanitizedTextInput
                      className={this.state.cityFieldInvalid ? 'is-invalid' : ''}
                      name="city"
                      id="employerCity"
                      type="text"
                      defaultValue={previousState.city || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    />
                  </FormGroup>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="postalCode"
                    id="employerPostalCode"
                    label="Zip / Postal Code"
                    valid={!this.state.postalCodeFieldInvalid}
                    validationMessage={this.state.postalCodeFieldValidationMessage}
                    value={previousState.postalCode || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <FormGroup>
                    <Label htmlFor="employerProvince">
                      State / Province
                    </Label>
                    <Input
                      className={this.state.stateFieldInvalid ? 'is-invalid' : ''}
                      type="select"
                      name="state"
                      id="employerProvince"
                      defaultValue={previousState.state || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey, true)}
                    >
                      <StatesAndProvincesOptions region={this.props.region} />
                    </Input>
                  </FormGroup>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="phoneNumber"
                    id="employerPhoneNumber"
                    label="Employer Phone"
                    valid={!this.state.phoneNumberFieldInvalid}
                    validationMessage={this.state.phoneNumberFieldValidationMessage}
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    maxLength="12"
                    helpText="###-###-####"
                    value={previousState.phoneNumber || ''}
                    onChange={this.handlePhoneNumber(context, dataTreeKey)}
                    onKeyPress={this.handleKeyPress}
                  />
                  <FormGroup>
                    <Label htmlFor="employmentType">
                      Employment Type
                      {this.props.highlightEssentialInputs ? '*' : null}
                    </Label>
                    <Input
                      className={this.state.employmentTypeFieldInvalid ? 'is-invalid' : ''}
                      type="select"
                      name="employmentType"
                      id="employmentType"
                      defaultValue={previousState.employmentType || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    >
                      <option value="">Please Select...</option>
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                      <option value="Contract">Contract</option>
                      <option value="Self-Employed">Self-Employed</option>
                      <option value="Seasonal">Seasonal</option>
                      <option value="Other">Other</option>
                    </Input>
                    <FormFeedback>{ this.state.employmentTypeFieldValidationMessage }</FormFeedback>
                  </FormGroup>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="jobTitle"
                    id="jobTitle"
                    label="Job Title"
                    required
                    valid={!this.state.jobTitleFieldInvalid}
                    validationMessage={this.state.jobTitleFieldValidationMessage}
                    value={previousState.jobTitle || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <FormGroup>
                    <Label htmlFor="durationAtEmployer">
                      Duration at Employer
                      {highlightDurationAtEmployer ? '*' : null}
                    </Label>
                    <Input
                      className={`short-width ${this.state.durationAtEmployerFieldInvalid ? 'is-invalid' : ''}`}
                      name="durationAtEmployer"
                      id="durationAtEmployer"
                      type="number"
                      defaultValue={previousState.durationAtEmployer || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    />
                    <span className="input-units">Months</span>
                    <FormFeedback>{this.state.durationAtEmployerFieldValidationMessage}</FormFeedback>
                  </FormGroup>
                </Container>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default EmployerInputGroup;
